<template>
  <div class="bottom-elements">
    <Searchbar />
  </div>
</template>

<script>
import Searchbar from '../components/Searchbar.vue'

export default {
  name: 'App',
  components: {
    Searchbar
  }
}
</script>
