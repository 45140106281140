<template>
  <div class="searchbar">
    <input v-model="inputValue"  type="text" placeholder="Bei Google suchen"/>

    <a :href="generateURL" target="_blank"></a>
  </div>
</template>

<script>
export default {
  computed: {
    generateURL: function () {
      return 'https://www.google.com/search?q=' + this.inputValue
    }
  },
  data: function () {
    return {
      inputValue: ''
    }
  },
  name: 'Searchbar'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.searchbar {
  display: flex;

  a {
    background-color: var(--theme-color);
    animation: pop-in 0.25s ease forwards;
    animation-delay: 0.5s;
    border: none;
    border-radius: 15px;
    display: block;
    height: 54px;
    margin-left: 15px;
    width: 54px;
    transform: scale(0);
    position: relative;

    &::after {
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwOCAxNDAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+CiAgICA8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLDAsMCwxLC0yMDU2LC0xNzE1KSI+CiAgICAgICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwwLDAsMSwxMjk2LDApIj4KICAgICAgICAgICAgPGcgaWQ9InN1Ym1pdC1idXR0b24iIHNlcmlmOmlkPSJzdWJtaXQgYnV0dG9uIj4KICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDYuMTIzMjNlLTE3LDEsLTAuNzY5NzY5LDQuNzEzNDhlLTE3LDIxODcuODcsLTM0MS4yMjMpIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjEyNiwxNzE1LjI4TDIxOTUuNSwxODU0LjI4TDIwNTYuNTEsMTg1NC4yOEwyMTI2LDE3MTUuMjhaIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0ibWF0cml4KDIuNjIxMTFlLTE3LDAuNDI4MDU5LC0wLjMyOTUwNywyLjAxNzY1ZS0xNywxMzcxLjYxLDg3NC43MjUpIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjEyNiwxNzE1LjI4TDIxOTUuNSwxODU0LjI4TDIwNTYuNTEsMTg1NC4yOEwyMTI2LDE3MTUuMjhaIiBzdHlsZT0iZmlsbDpyZ2IoMTE2LDE4NSwyNTUpOyIvPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
      background-size: 100% 100%;
      content: "";
      position: absolute;
      display: block;
      height: 50%;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input {
    animation: pop-in 0.25s ease forwards;
    animation-delay: 0.25s;
    background-color: var(--theme-color);
    border: none;
    border-radius: 15px;
    color: white;
    font-size: 18px;
    padding: 15px;
    transform: scale(0);
    width: calc(100% - 69px);

    &::placeholder {
      color: white;
    }

    &:focus,
    &:hover {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
}

@keyframes pop-in {
  from { transform: scale(0)}
  to { transform: scale(1)}
}
</style>
